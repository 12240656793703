import service from "../config.js";
import link from "../link.js";

let ApiUrl = link.IOtApiXiao.APIService;


//添加预约
export const Add = async (data) => {
    let responseData = await service({
        url: ApiUrl + "/api/Visitor/Insert",
        method: "post",
        data: data,
    });
    return responseData;
}
//审核通过
export const Pass = async (id, terminalCode, visitorCode) => {
    let responseData = await service({
        url: ApiUrl + "/api/Visitor/Pass",
        method: "post",
        params: {
            "id": id,
            "terminalCode": terminalCode,
            "visitorCode": visitorCode
        }
    });
    return responseData;
}
//驳回
export const Down = async (id) => {
    let responseData = await service({
        url: ApiUrl + "/api/Visitor/Down",
        method: "post",
        params: {
            "id": id
        }
    });
    return responseData;
}
//添加黑名单
export const AddBlackList = async (id) => {
    let responseData = await service({
        url: ApiUrl + "/api/Visitor/AddBlackList",
        method: "post",
        params: {
            "id": id
        }
    });
    return responseData;
}
//通过id删除
export const DelById = async (id) => {
    let responseData = await service({
        url: ApiUrl + "/api/Visitor/DelById",
        method: "post",
        params: {
            "id": id
        }
    });
    return responseData;
}
//获取访客数据
export const GetVisitor = async (timeType, timeNow, status, name, pageIndex, pageSize) => {
    let responseData = await service({
        url: ApiUrl + "/api/Visitor/GetVisitor",
        method: "get",
        params: {
            timeType: timeType,
            timeNow: timeNow,
            status: status,
            name: name,
            pageIndex: pageIndex,
            pageSize: pageSize
        }
    });
    return responseData;
}