<template>
	<div class="a-list">
		<div class="search-header">
			<div>
				<i @click="$router.go(-1)" class="iconfont icon-arrow02"></i>
			</div>
			<div>
				<van-search @click="showSearch = true" placeholder="搜姓名" v-model="searchVal" />
			</div>
			<div @click="$router.push({ path: '/SmartClassroom/AddAppointment' })">
				<i class="iconfont icon-yuangong_add"></i>
			</div>
		</div>
		<div>
			<div class="toggle-tab">
				<div>
					<div @click="toggleDate(0)" :class="{ active: date === 0 }">全部</div>
					<div @click="toggleDate(1)" :class="{ active: date === 1 }">上月</div>
					<div @click="toggleDate(2)" :class="{ active: date === 2 }">上周</div>
					<div @click="toggleDate(3)" :class="{ active: date === 3 }">
						{{ defineTime }}
					</div>
				</div>
				<div @click="showScreen = true">
					<span>{{StatusName}}</span>
					<i class="iconfont icon-fangjian_arrow"></i>
				</div>
			</div>
			<div class="toggle-list">
				<div v-for="(item, index) in visitorList" :key="index">
					<div>
						<div>
							<van-image
								width="61px"
								height="61px"
								fit="cover"
								:src="item.Photo"
							></van-image>
						</div>
						<div>
							<div class="toggle-title">
								<span>
									<i>{{ item.VisitorsName }}</i>
									<i v-show="item.ReviewStatus == 1" class="green">审核通过</i>
									<i v-show="item.ReviewStatus == 2" class="red">驳回</i>
									<i v-show="item.ReviewStatus == 3" class="gray">黑名单</i>
								</span>
								<span
									:class="{ active: item.VisitTime != null }"
									v-if="item.VisitTime != null"
									>已到访</span
								>
								<span
									:class="{ active: item.VisitTime == null }"
									v-if="item.VisitTime == null"
									>未到访</span
								>
							</div>
							<div class="toggle-person">
								<i class="iconfont icon-renyuan"></i>
								<span>被访人员：</span>
								<div>{{ item.ToPeople }}</div>
							</div>
							<div class="toggle-time">
								<i class="iconfont icon-shijian"></i>
								<span>到访时间：</span>
								<div>
									{{ dayjs.unix(item.StartTime).format('YYYY/MM/DD') }}
								</div>
							</div>
							<div class="toggle-phone">
								<i class="iconfont icon-jinjilianxiren"></i>
								<span>手机号码：</span>
								<div>{{ item.ToPeoplePhone }}</div>
							</div>
						</div>
					</div>
					<div>
						<!-- 未到访 -->
						<div
							@click="
								() => {
									showReject = true
									id = item.id
								}
							"
							v-if="item.ReviewStatus == 0"
						>
							<i class="iconfont icon-shibai"></i>
							<span>驳回</span>
						</div>
						<div @click="examineConfirm(item.id)" v-if="item.ReviewStatus == 0">
							<i class="iconfont icon-yuyue_shenhe"></i>
							<span>确认审核</span>
						</div>
						<!-- 已到访 -->
						<div
							@click="
								() => {
									showRecord = true
									id = item.id
								}
							"
							v-if="item.ReviewStatus != 0"
						>
							<i class="iconfont icon-yuanyin"></i>
							<span>记录黑名单</span>
						</div>
						<div
							@click="
								() => {
									showDelete = true
									id = item.id
								}
							"
							v-if="item.ReviewStatus != 0"
						>
							<i class="iconfont icon-shanchu"></i>
							<span>删除记录</span>
						</div>
					</div>
				</div>
				<div class="data-status">
					<van-empty v-if="!visitorList.length" description="暂无数据" />
					<div v-show="visitorList.length">
						<van-loading v-if="isLoad" size="14" />
						<span v-show="isLoad">数据加载中…</span>
						<div v-show="!isLoad">没有更多了</div>
					</div>
				</div>
			</div>
		</div>

		<van-popup v-model="showPickerTime" position="bottom">
			<van-datetime-picker
				type="date"
				v-model="currentDate"
				title="选择年月日"
				@confirm="onConfirmTime"
				@cancel="showPickerTime = false"
			/>
		</van-popup>
		<van-popup v-model="showSearch" position="bottom" style="height: 100%">
			<search-block
				@CancelSearch="
					() => {
						showSearch = false
						searchVal = ''
						this.visitorList = []
						this.GetVisitor()
					}
				"
				@SearchLoad="onLoad"
				@OnSearch="onSearch"
				:dataList="dataList"
				@clickSingle="setData"
			></search-block>
		</van-popup>
		<van-popup v-model="showScreen" position="bottom">
			<van-picker
				show-toolbar
				:columns="screenList"
				@confirm="confirmScreen"
				@cancel="showScreen = false"
			/>
		</van-popup>
		<van-action-sheet
			v-model="showReject"
			@select="rejectSelect"
			:actions="rejectAction"
			cancel-text="取消"
			close-on-click-action
			@cancel="showReject = false"
		/>
		<van-action-sheet
			v-model="showDelete"
			@select="deleteSelect"
			:actions="deleteAction"
			cancel-text="取消"
			close-on-click-action
			@cancel="showReject = false"
		/>
		<van-action-sheet
			v-model="showRecord"
			@select="recordSelect"
			:actions="recordAction"
			cancel-text="取消"
			close-on-click-action
			@cancel="showRecord = false"
		/>
	</div>
</template>
<script>
import { GetVisitor, Pass, Down, AddBlackList, DelById } from '@/api/Visitor/index.js'
import SearchBlock from '@/components/SearchBlock.vue'
import {
	Search,
	Grid,
	GridItem,
	Popup,
	Tab,
	Tabs,
	DatetimePicker,
	Picker,
	Image as VanImage,
	Toast,
	ActionSheet,
	Empty,
} from 'vant'
export default {
	components: {
		[Search.name]: Search,
		[Grid.name]: Grid,
		[GridItem.name]: GridItem,
		[Popup.name]: Popup,
		[Tab.name]: Tab,
		[Tabs.name]: Tabs,
		[DatetimePicker.name]: DatetimePicker,
		[Picker.name]: Picker,
		[VanImage.name]: VanImage,
		[Toast.name]: Toast,
		[ActionSheet.name]: ActionSheet,
		[Empty.name]: Empty,
		SearchBlock,
	},
	data() {
		return {
			date: 0,
			showReject: false,
			showDelete: false,
			showRecord: false,
			showPickerTime: false,
			showSearch: false,
			showScreen: false,
			rejectAction: [{ name: '确认驳回' }],
			deleteAction: [{ name: '确认删除记录', color: '#ff0000' }],
			recordAction: [{ name: '确认记录黑名单' }],
			currentDate: new Date(),
			defineTime: '自定义',
			dataList: [],
			screenList: [
				{ id: 5, text: '已到访' },
				{ id: 10, text: '未到访' },
				{ id: 0, text: '审核中' },
				{ id: 2, text: '审核失败' },
				{ id: 1, text: '审核通过' },
				{ id: 3, text: '黑名单' },
			],
			StatusName:"筛选",
			timeNow: 0,
			status: -1, //审核状态
			name: '', //姓名
			pageIndex: 1, //当前页码
			pageSize: 10, //当前页面大小
			pagerecords: 0, //总记录数

			scrollHeight: 0,
			offsetHeight: 0,
			isReachBottom: false,
			isLoad: false,
			visitorList: [], //预约数据
			id: 0, //选中的id
			searchVal: '',
			addUserCommand: null, //添加人员命令
			addUserFaceCommand: null, //添加人员人脸命令
			delUserCommand: null, //删除人员命令
			delUserFaceCommand: null, //删除人员人脸命令
			DoorUserId: null, //门禁唯一id
			visitorData: null, //当前选择id的预约信息
			ServerCode: '200010', //门禁编码
			handleStatus: 5, //5修改人脸 10删除人脸 15删除人
		}
	},

	methods: {
		setData() {},
		onLoad() {},
		onSearch(val) {
			this.searchVal = val
			this.visitorList = []
			this.GetVisitor()
			this.showSearch = false
		},
		//审核通过
		async examineConfirm(id) {
			let model = this.GetVisitorById(id)
			console.log(model)
			this.commnadJosn(this.addUserCommand.CommandJson)
		},
		async examineConfirm2() {
			let result = await Pass(this.visitorData.id, this.ServerCode, this.DoorUserId)
			if (result.ResultCode === 200) {
				//成功
				Toast.success('审核通过')
				this.visitorList = []
				this.GetVisitor()
			} else {
				Toast.fail('审核失败')
			}
		},
		//删除记录
		async deleteSelect() {
			let model = this.GetVisitorById(this.id)
			console.log(model)
			this.deleteUserJson(this.delUserCommand.CommandJson)
		},
		//删除记录
		async deleteSelect2() {
			let result = await DelById(this.id)
			if (result.ResultCode === 200) {
				//成功
				Toast.success('删除成功')
				this.visitorList = []
				this.GetVisitor()
			} else {
				Toast.fail('删除失败')
			}
		},
		//驳回记录
		async rejectSelect() {
			
			let result = await Down(this.id)
			if (result.ResultCode === 200) {
				//成功
				Toast.success('驳回成功')
				this.visitorList = []
				this.GetVisitor()
			} else {
				Toast.fail('驳回失败')
			}
		},
		GetVisitorById(id) {
			let that = this
			let result = null
			this.visitorList.forEach((item, index) => {
				if (item.id == id) {
					
					result = item
					that.visitorData = result
					return
				}
			})
			return result
		},
		//记录黑名单
		async recordSelect() {
			this.handleStatus = 10
			let model = this.GetVisitorById(this.id)
			console.log(model)
			this.deleteUserJson(this.delUserCommand.CommandJson)
		},
		//记录黑名单
		async recordSelect2() {
			let result = await AddBlackList(this.id)
			if (result.ResultCode === 200) {
				//成功
				Toast.success('记录黑名单成功')
				this.visitorList = []
				this.GetVisitor()
			} else {
				Toast.fail('记录黑名单失败')
			}
		},
		//替换命令的json格式
		commnadJosn(jsonData) {
			let time = this.dayjs.unix(this.visitorData.StartTime).format('YYYY-MM-DD') //2020-02-02
			jsonData = jsonData.replace('#ServersCode', '' + this.ServerCode + '')
			jsonData = jsonData.replace('#employeeNo', '' + this.DoorUserId + '')
			jsonData = jsonData.replace('#name', '' + this.visitorData.VisitorsName + '')
			jsonData = jsonData.replace('#userType', 'normal')
			jsonData = jsonData.replace('#enable', 'true')
			jsonData = jsonData.replace('#beginTime', '' + time + 'T00:00:01')
			jsonData = jsonData.replace('#endTime', '' + time + 'T23:59:59')
			jsonData = jsonData.replace('#timeType', 'local')
			jsonData = jsonData.replace('#doorRight', '1')
			jsonData = jsonData.replace('#doorNo', '1')
			jsonData = jsonData.replace('#planTemplateNo', '1')
			jsonData = jsonData.replace('#userVerifyMode', 'face')
			console.log(jsonData)
			let result = this.handdleMsg(jsonData) //添加人员的命令
			console.log(result) //
		},
		deleteUserJson(jsonData) {
			//{ "ServersCode":"#ServersCode","Msg":  {"UserInfoDetail": {"mode": "#mode","EmployeeNoList": [{"employeeNo": "#employeeNo"}]}},"Command":15 }
			jsonData = jsonData.replace('#ServersCode', '' + this.ServerCode + '')
			jsonData = jsonData.replace('#mode', 'byEmployeeNo')
			jsonData = jsonData.replace('#employeeNo', '' + this.visitorData.VisitorCode + '')
			console.log(jsonData)
			let result = this.handdleMsg(jsonData) //添加人员的命令
			console.log(result) //
		},
		//websocket消息
		async handdleMsg(msg) {
			let that = this
			let wss=null;
			that.global.ws.forEach((item,i)=>{
				if (item.name == "door")
                { wss=item.info;}
			});
			if (wss && wss.readyState == 1) {
				console.log('发送信息', msg)
				wss.send(msg)
			}
			wss.onmessage = function (res) {
				console.log('收到服务器内容', res.data)
				console.log(JSON.parse(res.data).Msg)
				let result = JSON.parse(res.data).Msg
				if(JSON.parse(result).Code==500){
					Toast.fail("图片有问题请重新选择图片");
					return;
			    }
				if (
					JSON.parse(result).Code == 200 &&
					JSON.parse(result).Data.Msg == 'Set User Success'
				) {
					let b = that.addUserFaceCommand.CommandJson
					b = b.replace('#ServersCode', '' + that.ServerCode + '')
					b = b.replace('#faceLibType', 'blackFD')
					b = b.replace('#FDID', '1')
					b = b.replace('#FPID', '' + that.DoorUserId + '')
					b = b.replace('#ImageUrl', '' + that.visitorData.Photo + '')
					that.handdleMsg(b) //添加人员的命令
				}
				if (
					JSON.parse(result).Code == 200 &&
					JSON.parse(result).Data.Msg == 'Set Face Success'
				) {
					that.examineConfirm2()
				}
				if (
					JSON.parse(result).Code == 200 &&
					JSON.parse(result).Data.Msg == 'Del User Success' &&
					that.handleStatus == 5
				) {
					that.deleteSelect2()
				}
				if (
					JSON.parse(result).Code == 200 &&
					JSON.parse(result).Data.Msg == 'Del User Success' &&
					that.handleStatus == 10
				) {
					that.recordSelect2()
				}
			}
		},
		setData() {
			this.showSearch = false
		},
		confirmScreen(val) {
			this.showScreen = false;
			this.StatusName=val.text;
			this.status=val.id;
			this.visitorList = []
			this.GetVisitor();
			this.status=-1;
			// console.log(val)
		},
		turnpageFunc(e) {
			let reachBottomDistance = 100 //距离底部100像素触发
			let scrollTop = e.target.scrollTop
			let scrollHeight = e.target.scrollHeight
			let offsetHeight = Math.ceil(e.target.getBoundingClientRect().height)
			let currentHeight = scrollTop + offsetHeight + reachBottomDistance
			if (currentHeight < this.scrollHeight && this.isReachBottom) {
				this.isReachBottom = false
			}
			if (this.isReachBottom) {
				return
			}
			if (currentHeight >= scrollHeight) {
				this.pageIndex++
				console.log('触底了,可以翻页加载数据了')
				this.GetVisitor()
			}
		},
		toggleDate(num) {
			// 切换状态
			if (parseInt(num) === 3) {
				// 是否是从其他状态切换到3
				if (this.date != 3) {
					// 是否是自定义，还未赋值时间
					if (this.defineTime === '自定义') {
						this.showPickerTime = true
					}
					this.date = parseInt(num)
				} else {
					this.showPickerTime = true
				}
			} else {
				this.date = parseInt(num)
				this.visitorList = []
				this.GetVisitor()
			}
		},
		onConfirmTime(time) {
			this.defineTime = this.dayjs(time).format('YYYY-MM-DD')
			this.showPickerTime = false
			this.date = 3
			this.visitorList = []
			this.GetVisitor()
		},
		handleClickSlide() {},
		//数据加载
		async GetVisitor() {
			this.isReachBottom = false
			let timeNow = -1
			if (this.defineTime != '自定义') {
				timeNow = this.dayjs(this.defineTime).unix()
			}
			let result = await GetVisitor(
				this.date,
				timeNow,
				this.status,
				this.searchVal,
				this.pageIndex,
				this.pageSize
			)
			this.pagerecords = result.Pagerecords //总数
			if (result.ResultCode === 206) {
				console.log('没数据')
				this.isLoad = false
				this.isReachBottom = true
				return
			}
			this.visitorList.push(...result.Data)
			if (this.visitorList.length >= this.pagerecords) {
				this.isLoad = false
				this.isReachBottom = true
				console.log('加载完毕' + this.visitorList.length + '_' + this.pagerecords)
				return
			}
			this.isLoad = true
			console.log(this.pageIndex)
		},
	},
	async mounted() {
		console.log(this.$router)
		this.DoorUserId = await this.defineMethods.genID()
		console.log(this.DoorUserId)
		JSON.parse(this.defineMethods.getSessionStorage('TerminalCommandList')).forEach(
			(item, index) => {
				if (item.CommandType == 5 && item.TerminalType == 4) this.addUserCommand = item
				if (item.CommandType == 7 && item.TerminalType == 4) this.addUserFaceCommand = item
				if (item.CommandType == 6 && item.TerminalType == 4) this.delUserCommand = item
				if (item.CommandType == 8 && item.TerminalType == 4) this.delUserFaceCommand = item
			}
		)
		console.log(this.addUserCommand)
		console.log(this.addUserFaceCommand)
		console.log(this.delUserCommand)
		console.log(this.delUserFaceCommand)
	},
	beforeRouteEnter(to, from, next) {
		next((vm) => {
			vm.visitorList = []
			vm.GetVisitor()
		})
	},
}
</script>
<style lang="scss" scoped>
@import '../../../style/public.scss';
.a-list {
	width: 100%;
	height: 100%;
	background-color: $bgColor;
	> div:nth-child(2) {
		height: 100%;
	}
	.toggle-tab {
		display: flex;
		flex-flow: row nowrap;
		justify-content: space-between;
		align-items: center;
		background: #fff;
		padding: 8px 16px 0 8px;
		> div:first-child {
			display: flex;
			flex-flow: row nowrap;
			justify-content: space-between;
			align-items: center;
			> div {
				padding: 8px;
				position: relative;
				overflow: hidden;
				font-size: 15px;
				font-weight: 400;
				color: $titleColor;
			}
			> div.active {
				color: $themeColor;
			}
			> div:before {
				content: '';
				position: absolute;
				bottom: 0;
				left: 0;
				transform: translateX(-100%);
				width: 24px;
				height: 3px;
				background-color: $themeColor;
				border-radius: 5px;
			}

			> div.active:before {
				content: '';
				left: 50%;
				transform: translateX(-50%);
			}
		}
		> div:last-child {
			font-size: 15px;
			font-weight: 400;
			color: $titleColor;
			> i {
				font-size: 12px;
				display: inline-block;
				transform: scale(0.6);
				margin-left: 2px;
			}
		}
	}
	.toggle-list {
		width: 100%;
		height: calc(100% - 110px);
		overflow-y: scroll;
		.data-status {
			> div:last-child {
				height: 18px;
				display: flex;
				flex-flow: row nowrap;
				justify-content: center;
				align-content: center;
				width: 100%;
				margin-top: 16px;
				color: $gray;
				font-size: 14px;
				> div:first-child {
					align-self: flex-end;
					margin-right: 10px;
				}
			}
		}
		> div:not(.data-status) {
			height: 168px;
			margin-left: 16px;
			margin-right: 16px;
			background-color: #fff;
			border-radius: 8px;
			margin-top: 16px;
			.toggle-person,
			.toggle-time,
			.toggle-phone {
				margin-top: 8px;
				> i {
					display: inline-block;
					width: 18px;
					height: 18px;
					color: $themeColor;
					margin-right: 4px;
				}
				> span {
					font-size: 14px;
					font-weight: 400;
					color: $subTitleColor;
					margin-right: 16px;
				}
				> div {
					display: inline-block;
					font-size: 14px;
					font-weight: 400;
					color: $titleColor;

					font-size: 14px;
					font-weight: 400;
				}
			}
			.toggle-title {
				display: flex;
				flex-flow: row nowrap;
				justify-content: space-between;
				align-items: center;
				> span:first-child {
					font-size: 18px;
					font-weight: bold;
					color: $titleColor;
					> i:not(:first-child) {
						font-size: 12px;
						margin-left: 4px;
						display: inline-block;
						padding: 2px 8px;
						transform: scale(0.85);
						border-radius: 6px;
					}
					> i.red {
						color: $red;
						background: #ffe5e5;
					}
					> i.green {
						color: $green;
						background: #def8ed;
					}
					> i.gray {
						color: $subTitleColor;
						background: $bgColor;
					}
				}
				> span:last-child {
					font-size: 12px;
					font-weight: 400;
				}
				span {
					position: relative;
				}
				> span:last-child:before {
					content: '';
					position: absolute;
					left: -50%;
					top: 50%;
					transform: translate(50%, -50%);
					width: 5px;
					height: 5px;
					background: $defocus;
					box-shadow: 0px 0px 6px $grayShadow;
					border-radius: 50%;
				}
				> span:last-child.active:before {
					content: '';
					position: absolute;
					left: -50%;
					top: 50%;
					transform: translate(50%, -50%);
					width: 5px;
					height: 5px;
					background: $themeColor;
					box-shadow: 0px 0px 6px $themeColor;
					border-radius: 50%;
				}
			}
			> div:first-child {
				height: 132px;
				width: 100%;
				padding: 16px;
				display: flex;
				flex-flow: row nowrap;
				justify-content: space-between;
				align-items: flex-start;
				> div:first-child {
					margin-right: 12px;
					width: 61px;
					height: 61px;
					overflow: hidden;
					border-radius: 6px;
				}
				> div:last-child {
					flex: 1;
				}
			}
			> div:last-child {
				display: flex;
				flex-flow: row nowrap;
				justify-content: space-between;
				align-items: center;
				height: 36px;
				line-height: 36px;
				border-top: 1px solid $bgColor;
				i {
					margin-right: 5px;
				}
				> div:not(:last-child) {
					border-right: 1px solid $bgColor;
				}
				> div {
					text-align: center;
					flex: 1;
				}
			}
		}
	}
	.v-search-header {
		height: 56px;
		padding: 0 24px;
		width: 100%;
		display: flex;
		flex-flow: row nowrap;
		justify-content: space-between;
		align-items: center;
		border-bottom: 1px solid #f4f4f5;
		> span:first-child {
			font-size: 17px;
			font-weight: 400;
			color: $titleColor;
		}
		> span:last-child {
			color: $gray;
			font-size: 22px;
		}
	}
	.search-header {
		display: flex;
		flex-flow: row nowrap;
		justify-content: center;
		align-items: center;
		padding-right: 16px;
		padding-top: 10px;
		background: #fff;
		.van-search__content {
			border-radius: 8px;
		}
		.van-search {
			padding: 0;
		}
		> div:first-child {
			width: 28px;
			height: 28px;
			text-align: center;
			line-height: 28px;
			margin-left: 8px;
			color: $titleColor;
			> i {
				font-size: 20px;
			}
		}
		> div:nth-child(2) {
			flex: 1;
			.van-search {
				background: $bgColor;
				border-radius: 8px;
				overflow: hidden;
			}
		}
		> div:last-child {
			text-align: center;
			line-height: 28px;
			margin-left: 16px;
		}
	}
	.list {
		box-sizing: border-box;
		padding-top: 14px;
		width: 100%;
		height: calc(100% - 150px);
		overflow-y: scroll;
		.van-grid-item__content {
			border-radius: 5px;
			box-shadow: 0px 0px 10px #ebebeb;
		}
	}
	.popup-list {
		height: 500px;
		overflow-y: scroll;
		> div {
			height: 45px;
			line-height: 45px;
			color: #555;
			padding-left: 24px;
			font-size: 13px;
			font-weight: 400;
			color: #555555;

			i {
				display: none;
				margin-right: 5px;
				color: $themeColor;
			}
		}

		> div.active {
			color: $titleColor;
			i {
				display: inline-block;
			}
		}
	}
	.list-item {
		width: 100%;
		height: 100%;
		position: relative;
		.l-sign {
			position: absolute;
			top: -16px;
			right: -10px;
			color: $themeColor;
			opacity: 0.1;
			> i {
				font-size: 60px;
			}
		}
		.l-title {
			font-size: 17px;
			font-weight: 400;
			line-height: 24px;
			color: #2a2a2a;
			margin-bottom: 6px;
		}
		.l-subtitle {
			font-size: 12px;
			font-weight: 400;
			color: #949494;
			margin-bottom: 18px;
		}
		.l-count {
			display: flex;
			flex-flow: row nowrap;
			justify-content: space-between;
			align-items: center;
			> div:first-child {
				.count {
					> span {
						display: inline-block;
						font-size: 12px;
						transform: scale(0.95);
						font-weight: 400;
						color: $titleColor;
					}
					> span:first-child {
						font-size: 18px;
						font-weight: 500;
						color: $themeColor;
						transform: unset;
					}
				}
				.close {
					font-size: 11px;
					font-weight: 500;
					color: #949494;
				}
			}
		}
	}
	.toggle-equipment.active {
		background: $themeColor;
		color: #fff;
	}
	.toggle-equipment {
		width: 107px;
		height: 34px;
		line-height: 34px;
		text-align: center;
		box-shadow: 0px 0px 5px rgba(200, 200, 200, 0.5);
		border-radius: 8px;
		font-size: 14px;
		font-weight: 400;
		color: #2a2a2a;
		background: #fff;
		i {
			margin-right: 7px;
		}
	}
	.switch-info {
		display: flex;
		flex-flow: row nowrap;
		justify-content: flex-start;
		align-items: center;
		margin-top: 16px;
		margin-left: 20px;
		margin-bottom: 16px;
		> div {
			display: flex;
			flex-flow: row nowrap;
			justify-content: center;
			align-items: center;
			> i:first-child {
				font-size: 16px;
				color: $themeColor;
				margin-right: 5px;
			}
			span {
				font-size: 15px;
				font-weight: 400;
				color: $titleColor;
			}
			> i:last-child {
				font-size: 12px;
				display: inline-block;
				transform: scale(0.78);
				margin-left: 3px;
				align-self: flex-end;
				padding-bottom: 5px;
			}
		}
	}
}
</style>
<style lang="scss">
@import '../../../style/public.scss';
.a-list {
	.van-popup {
		overflow: hidden;
	}
	.van-cell,
	.van-cell__value--alone {
		color: $gray;
	}
	.van-tabs {
		height: 100%;
		.van-tabs__nav {
			background-color: transparent;
		}
		.van-tabs__wrap {
			height: 34px;
			.van-tab {
				border-radius: 10px 10px 0px 0px;
				flex: inherit;
				padding: 0 20px;
			}
		}
		.van-tabs__content {
			height: calc(100% - 34px);
			._container,
			.scroller {
				height: 100%;
			}
		}
		.van-tabs__line {
			background: $themeColor;
		}
	}
}
</style>